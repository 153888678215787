import React, { createContext, useState } from 'react';

export const MessageContext = createContext();

export const MessageProvider = ( { children } ) =>
{
	const [messages, setMessages] = useState([]);

	const addMessage = ( newMessage ) =>
	{
		setMessages((prevMessages) => [...prevMessages, newMessage]);
	};

	const clearMessages = () =>
	{
		setMessages([]);
	};

	return (
		<MessageContext.Provider value={{ messages, addMessage, clearMessages }}>
			{children}
		</MessageContext.Provider>
  );
};

import CryptoJS		from 'crypto-js'
import { httpApiUrl }   from './Global'

var lang = "fa";


export function sameValue( val1 , val2 , diffRatio = 1e-12 )
{
    return Math.abs( val1 - val2 ) <= diffRatio * Math.abs( val1 + val2 );
}

export function roundTo( val , step )
{
    if( step === 0 )
        return Math.round( Number( val ) );
    val = Math.round( Number( val ) / step ) * step;
    let n = Math.max( 0 , -Math.log10( step ) );
    val = val.toFixed( Math.ceil( n ) );
    return Number( val );
}

export function floorTo( val , step )
{
    if( step === 0 )
        return Math.floor( Number( val ) );
    val = Math.floor( Number( val ) / step ) * step;
    let n = Math.max( 0 , -Math.log10( step ) );
    val = val.toFixed( Math.ceil( n ) );
    return Number( val );
}

export function ceilTo( val , step )
{
    if( step === 0 )
        return Math.ceil( Number( val ) );
    val = Math.ceil( Number( val ) / step ) * step;
    let n = Math.max( 0 , -Math.log10( step ) );
    val = val.toFixed( Math.ceil( n ) );
    return Number( val );
}

export function floatNumPattern( lang )
{
    return lang === "fa" ? "([۰-۹]|[٠-٩])+\\.?([۰-۹]|[٠-٩])*" : "[0-9]+\\.?[0-9]*";
}

export function toFaDigits( str )
{
    str = str.toString();

    //replace( /([۰-۹])/g , token => String.fromCharCode( token.charCodeAt(0) - 1728 ) )

    const arr = ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
    return str.replace(/[0-9]/g , w => arr[+w] );
}

export function toEnDigits( str )
{
    str = str.toString();
    return str.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) ).replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) );
}

export function convDigits( str )
{
    return lang === 'fa' ? toFaDigits( str ) : str;
}

export function jalaliMonthName( m )
{
    m = toEnDigits( m );

    const names =
    [
        "فروردین" ,
        "اردیبهشت" ,
        "خرداد" ,
        "تیر" ,
        "مرداد" ,
        "شهریور" ,
        "مهر" ,
        "آبان" ,
        "آذر" ,
        "دی" ,
        "بهمن" ,
        "اسفند"
    ];

    if( m < 1 || m > 12 )
        return "";

    return names[ m - 1 ];
}

export function dateStr( date )
{
    const daysNames = [ "یکشنبه" , "دوشنبه" , "سه\u200cشنبه" , "چهارشنبه" , "پنج\u200cشنبه" , "جمعه" , "شنبه" ]

    const arr = date.toLocaleDateString( "fa-IR" ).split( "/" );

    return daysNames[ date.getDay() ] + " " + convDigits( arr[ 2 ] ) + " " + jalaliMonthName( arr[ 1 ] );
}

export function dtStr( dt , format = "d t" )
{
    var date = new Date( dt );
    if( Number.isNaN( date.getDate() ) )
        return "";

    // let dateStr = lang === "fa" ?
        // convDigits( date.toLocaleDateString( "fa-IR" ) ) :
        // date.toLocaleDateString( "zh-Hans-CN" ); // en-US zh-Hans-CN

    let dateStr = lang === "fa" ?
        toEnDigits( date.toLocaleDateString( "fa-IR" ) ) :
        date.toLocaleDateString( "zh-Hans-CN" ); // en-US zh-Hans-CN

    if( format.includes( "?" ) )
    {
        var now  = new Date();
        let todayStr = lang === "fa" ?
            convDigits( now.toLocaleDateString( "fa-IR" ) ):
            now.toLocaleDateString( "zh-Hans-CN" ); // en-US zh-Hans-CN

        format = format.replace( /\?/g , "" );
        if( dateStr === todayStr )
        {
            format = format.replace( /d/g , "" );
            format = format.replace( /s/g , "" );
        }
    }

    // let timeStr = convDigits( date.toLocaleTimeString( 'en-US', { hour12: false } ) );
    let timeStr = toEnDigits( date.toLocaleTimeString( 'en-US', { hour12: false } ) );

    dateStr = dateStr.replace( /\/(\d)$/ , t => "/0" + t[ 1 ] );

    format = format.replace( "d"  , dateStr )
    format = format.replace( "s"  , dateStr.slice( 5 ) )
    format = format.replace( "t"  , timeStr )
    format = format.replace( "hm" , timeStr.slice( 0 , 5 ) )

    return format;
}

export function hmac( message , secret )
{
    if( !message || message.length < 4 || !secret || secret.length < 4 )
        return "";

    return CryptoJS.HmacSHA256( message , secret ).toString( CryptoJS.enc.Base64 );
}

export function hmacHex( message , secret )
{
    if( !message || message.length < 4 || !secret || secret.length < 4 )
        return "";

    return CryptoJS.HmacSHA256( message , secret ).toString( CryptoJS.enc.Hex );
}

export function copyToClipboard( str )
{
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute( 'readonly', '' );
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand( 'copy' );
    document.body.removeChild( el );
};


export function getCookie( cname )
{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent( document.cookie );
    var ca = decodedCookie.split(';');

// console.log( 'document.cookie' , decodedCookie );

    for( var i = 0; i < ca.length ; i++ )
    {
        var c = ca[i].trim();
        if( c.indexOf(name) === 0 )
            return c.substring(name.length, c.length);
    }

    return "";
}

export function setCookies( ld , age = 4 * 24 *3600 )
{
console.log( "setCookies" , ld , age );
    if( ld && ld.tid )
    {
        document.cookie = "tid="        + ld.tid      + "; Max-Age=" + age + "; path=/; SameSite=Strict;";
        document.cookie = "username="   + ld.username + "; Max-Age=" + age + "; path=/; SameSite=Strict;";
        if( ld.type )
            document.cookie = "ut="     + ld.type     + "; Max-Age=" + age + "; path=/; SameSite=Strict;";

        localStorage.setItem( "sid" , ld.sid );
    }
    else
    {
        document.cookie = "tid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "ut=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        localStorage.removeItem( "sid" );
    }
};

export function updateCookies( age = 4 * 24 *3600 )
{
console.log( "updateCookies" , age );
    setCookies( { tid : getCookie( "tid") , sid : localStorage.getItem( "sid" ) , username : getCookie( "username") , type : getCookie( "ut") } , age );
};

export function makeFetchParams( api , data = undefined , method = "GET" )
{
	const url  = "/api/v1/" + api;
	const time = Date.now();
// console.log( ">>>>>>>>>" , data );
    const params =
    {
        method: method,
        headers: {
          "Content-Type"    : 'application/json; charset=utf-8' ,
          "Api-Key"         : getCookie( "tid" ) ,
          "Api-Signature"   : hmac( method + url + ":" + time , localStorage.getItem( "sid" ) ) ,
          // "Api-Signature"   : hmac( method + url + ":" + time , getCookie( "sid" ) ) ,
          "Api-Time"        : time ,
          // "Perfex-Lang"     : lang ,
        },
    };

	if( data )
		params.body = JSON.stringify( data );

    return [ httpApiUrl + url , params ];
}

export function makeFetchParamsPub( api , data = undefined , method = "GET" )
{
    const url  = "/api/v1/" + api;
// console.log( ">>>>>>>>>" , data );

    const params =
    {
        method: method,
        headers: {
          "Content-Type"    : 'application/json; charset=utf-8',
          // "Perfex-Lang"     : lang,
        },
    };

	if( data )
		params.body = JSON.stringify( data );

    return [ httpApiUrl + url , params ];
}


import React from 'react';
import { useParams , Link  } from "react-router-dom";

import { makeFetchParamsPub , dtStr } from './Utils.js';
import NewOrder from './NewOrder';
import { MessageContext } from './Utils/MessageContext';
import WithdrawWindow  from './WithdrawWindow'
import './Orders.css';

var params;

export default function Orders()
{
	const [ newOrderIsOpen 		, setNewOrderIsOpen 		] = React.useState( false 	);
	const [ withdrawWinIsOpen 	, setWithdrawWinIsOpen 		] = React.useState( false 	);
	const [ data 				, setData		 			] = React.useState( {}  	);
	const [ slippage 			, setSlippage	 			] = React.useState( 0.2 	);
	const [ fetchOrdersInterval , setFetchOrdersInterval 	] = React.useState( 2000 	);
	const [ currentOrderId 		, setCurrentOrderId 		] = React.useState( -1 		);
	const [ autoTrade 			, setAutoTrade				] = React.useState( false   );

	const { addMessage } = React.useContext( MessageContext );


	params = useParams();

	const getPage = () => parseInt( params.page.match( /^p(\d+)$/ )[ 1 ] )

	const fetchOrders = page =>
	{
		if( !page )
			return;

		fetch( ...makeFetchParamsPub( "orders/" + page , undefined , "GET" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
				setData( out.result );
				// console.log( "..." , Date.now() , out.result );
			}
			else if( out.status === "error" && out.error )
			{
				if( out.error.message && out.error.message.length )
					addMessage( out.error.message );

				if( out.error.code === 20 )
					setFetchOrdersInterval( 30000 );

				console.log( out.error , fetchOrdersInterval );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const fetchSettings = s =>
	{
		fetch( ...makeFetchParamsPub( "settings" , s , "POST" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
				setAutoTrade( out.result.auto_trade );
			}
			else if( out.status === "error" && out.error )
			{
				if( out.error.message && out.error.message.length )
					addMessage( out.error.message );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const trade = id =>
	{
		fetch( ...makeFetchParamsPub( "order/trade" , { id : id , slippage:slippage } , "POST" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
			}
			else if( out.status === "error" && out.error )
			{
				if( out.error.message && out.error.message.length )
					addMessage( out.error.message );
				if( out.result )
					addMessage( JSON.stringify( out.result ) );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const withdraw = ( id , txid = null ) =>
	{
// console.log( "id :" , id , "    txid :" , txid );
// return;
		fetch( ...makeFetchParamsPub( "withdraw" , { id : id , ...( txid && { tx_id : txid } ) } , "POST" ) )
		.then( res => res.json() )
		.then( out =>
		{
			if( out.status === "ok" && out.result )
			{
			}
			else if( out.status === "error" && out.error )
			{
				if( out.error.message && out.error.message.length )
					addMessage( out.error.message );
				if( out.result )
					addMessage( JSON.stringify( out.result ) );
			}
		} )
		.catch( err =>
		{
			// alert( err )
		} );
	}

	const onWithdrawClick = ( id ) =>
	{
		setCurrentOrderId( oldId =>
		{
			setWithdrawWinIsOpen( true );
			return id;
		} );
	}

	const renderHeader = () =>
	{
		return(
			<thead>
				<tr>
					<th> ID			</th>
					<th> Side		</th>
					<th> Market		</th>
					<th> Amount		</th>
					<th> Filled		</th>
					<th> Price		</th>
					<th> CurrentPrice</th>
					<th> CreateTime	</th>
					<th> UpdateTime	</th>
					<th> State		</th>
					<th> WithState	</th>
					<th> Address	</th>
					<th> Network	</th>
					<th> Trade		</th>
					<th> Withdraw	</th>
					<th> TxID		</th>
					<th> Memo		</th>
				</tr>
			</thead>
		);
	}

	const renderRow = ( order , index ) =>
	{
		const capStr = string => !string || string.length < 1 ? "" : string.charAt(0).toUpperCase() + string.slice(1);


		return(
			<tr className = { (index & 1) === 0 ? "even" : "odd" } key={index}>
				<td> { order.id						} </td>
				<td> { order.side					} </td>
				<td> { order.market					} </td>
				<td> { order.amount					} </td>
				<td> { order.filled_amount			} </td>
				<td> { order.price					} </td>
				<td> { order.current_price		    } </td>
				<td> { dtStr( order.create_time )	} </td>
				<td> { dtStr( order.update_time )	} </td>
				<td> { order.state					} </td>
				<td> { order.withdraw_state			} </td>
				<td> { order.address				} </td>
				<td> { order.network				} </td>
				<td> <button
						disabled= { order.state.toLowerCase() === "done" }
						onClick = { ()=>{ trade( order.id ) } } > {capStr(order.side)}
					 </button>
				</td>
				<td> <button
						disabled= { order.state.toLowerCase() !== "done" || !order.withdraw_state || order.withdraw_state.toLowerCase() === "done" }
						onClick = { onWithdrawClick.bind( this , order.id ) } > Withdraw
					 </button>
				</td>
				<td className="txid"> <a href={ order.explorer_tx_url } >{ order.tx_id} </a></td>
				<td> { order.memo					} </td>
			</tr>
		);
	}
						// onClick = { ()=>{ withdraw( order.id ) } } > Withdraw

	const renderBody = () =>
		<tbody>
			{ data && data.orders && data.orders.map( renderRow ) }
		</tbody>

	//------------------

	const setSlpValue = e =>
	{
		if( e.target.value.match( "^[\\d\\.]{0,16}$" ) != null )
			setSlippage( e.target.value );
	}

	const closeNewOrder = ( delay = true ) =>
	{
		console.log( "DDDDD" , delay );
		if( delay )
		{
			setTimeout( ()=>
			{
				fetchOrders();
			} , 200 );

			setTimeout( ()=>
			{
				setNewOrderIsOpen( false );
			} , 300 );
		}
		else
			setNewOrderIsOpen( false );
	}


	React.useEffect( () =>
	{
		fetchOrders( getPage() );
		fetchSettings( {} );
	} , [] );

	React.useEffect( () =>
	{
		const intervalId = setInterval( () =>
		{
			fetchOrders( getPage() );
		} , fetchOrdersInterval );
		return () => clearInterval( intervalId );
	} , [ fetchOrdersInterval ] );

	const handleCheckboxChange = () =>
	{
		setAutoTrade( !autoTrade );
		fetchSettings( { auto_trade : !autoTrade } );
	};

	return(
		<div className="orders" >
			<Link className="link" to={`/orders/p${ getPage() > 1 ? getPage() - 1 : 1 }` 									} replace={true} > Previous </Link>
			<Link className="link" to={`/orders/p${ data && data.orders && data.orders.length ? getPage() + 1 : getPage() }`} replace={true} > Next     </Link>
			<label className="header"> Slippage </label>
			<input type="number" step="0.1" min="0.0" max="4.0" className="slippage" name="slippage" value={slippage} onChange = { setSlpValue } />

			<label className="header">
				<input
				  type="checkbox"
				  checked={autoTrade}
				  onChange={ handleCheckboxChange }
				/>
				Auto Trade
			</label>

			<button style = {{ marginLeft: '20px' }} onClick = { ()=>{setNewOrderIsOpen( true ) } } >New Order</button>

			<table>
				{ renderHeader() }
				{ renderBody() }
			</table>

			<NewOrder isOpen = { newOrderIsOpen } onClose = { closeNewOrder.bind( this ) } slippage = { slippage }/>
			<WithdrawWindow isOpen = { withdrawWinIsOpen } onClose = { setWithdrawWinIsOpen.bind( this , false ) } withdraw = { withdraw.bind( this , currentOrderId ) }/>
		</div>
	);
};
import React from 'react';
import { MessageContext } from './MessageContext';
import DraggableModal from './DraggableModal';
import './MessageBox.css';

const MessageBox = () =>
{
	const { messages, clearMessages } = React.useContext(MessageContext);

	return (
		<DraggableModal isOpen = {messages && messages.length}>
			<h4 className="message-box-title">Messages</h4>
			<div className="message-content">
			{
				messages.length === 0 ?
				<p>No messages</p>
			 :
				messages.map( ( msg , index ) =>
					<div key={index}>
						<p>{msg}</p>
					</div>
				)
			}
			</div>
			<button onClick={clearMessages} className="ok-button">OK</button>
		</DraggableModal>
	);
};

export default MessageBox;



import React, { useState } from 'react';
import DraggableModal from './Utils/DraggableModal';

const WithdrawWindow = ( props ) =>
{
	const [ txid , setTxid ] = useState( "" );

	const handleWithdraw = () =>
	{
		props.withdraw();
		onClose();
	};

	const handleManualWithdraw = () =>
	{
		props.withdraw( txid );
		onClose();
	};

	const onClose = e =>
	{
		setTxid( "" );
		props.onClose();
	}

	return(
		<DraggableModal isOpen = {props.isOpen} onClose = { onClose } >
			<input
				type="text"
				value={txid}
				onChange={(e) => setTxid(e.target.value)}
				placeholder="Enter txid"
				style = {{ minWidth : '400px' , padding : '8px' }}
			/>

			<button onClick={ handleManualWithdraw } disabled = { txid.length < 20 } >
				Manual Withdraw
			</button>
			<button onClick={ handleWithdraw } disabled = { txid.length > 0 } >
				Withdraw
			</button>

		</DraggableModal>
	);
};

export default WithdrawWindow;

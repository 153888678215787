import React, { useState, useRef, useEffect } from 'react';
import './DraggableModal.css';

const DraggableModal = ({ isOpen, onClose, children }) =>
{
	const [isDragging, setIsDragging] = useState( false );
	const [offset, setOffset] = useState( { x: 0, y: 0 } );
	const [position, setPosition] = useState(
	{
		left: window.innerWidth  / 2 - 300 ,
		top:  window.innerHeight / 2 - 300 ,
	} );
	const divRef = useRef( null );

	useEffect( () =>
	{
		if( divRef.current && isOpen )
		{
			setPosition(
			{
				left: window.innerWidth  / 2 - divRef.current.getBoundingClientRect().width  / 2 ,
				top:  window.innerHeight / 2 - divRef.current.getBoundingClientRect().height / 2 ,
			} );
		}
	} , [isOpen] );

	const handleMouseDown = (e) =>
	{
		setIsDragging( true );
		setOffset(
		{
			x: e.clientX - divRef.current.getBoundingClientRect().left ,
			y: e.clientY - divRef.current.getBoundingClientRect().top  ,
		} );
	};

	const handleMouseMove = (e) =>
	{
		if( isDragging )
			setPosition(
			{
				left: e.clientX - offset.x ,
				top:  e.clientY - offset.y ,
			} );
	};

	const handleMouseUp = () =>
	{
		setIsDragging( false );
	};

	const handleOverlayClick = (e) =>
	{
		e.stopPropagation();
		if( onClose )
			onClose();
	};

	if( !isOpen )
		return null;

	return (
		<div className="modal-overlay" onClick={handleOverlayClick}>
			<div
				className="draggable-modal"
				ref={divRef}
				style={{ top: position.top, left: position.left, position: 'absolute' }}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseUp}
				onClick={(e) => e.stopPropagation()} // جلوگیری از بستن مودال هنگام کلیک داخل آن
			>
				{children}


				<button
					className="close-button"
					onClick={onClose}
					style={{
						position: 'absolute',
						padding: '8px',
						paddingTop: '1px',
						maxHeight: '24px',
						top: '0px',
						right: '5px',
						height: 'auto',
						background: 'transparent',
						border: 'none',
						fontSize: '32px',
						cursor: 'pointer',
						color: 'black',
						overflow: 'hidden',
					}}
				>
					˟
				</button>
			</div>
		</div>
	);
};

export default DraggableModal;
